// src/components/Watermark.js
import {useEffect} from 'react';

function Watermark() {
    useEffect(() => {
        // Создаём невидимый водяной знак
        const watermark = document.createElement('div');
        watermark.id = 'watermark';
        watermark.style.position = 'fixed';
        watermark.style.left = '50%';
        watermark.style.top = '25%';
        watermark.style.opacity = '50%';
        watermark.style.transform = 'translate(-50%, -50%)';
        watermark.style.textShadow = '0 0 WHITE';
        watermark.style.fontSize = '5vh';
        watermark.style.width = 'max-content';
        watermark.innerHTML = 'Created by @bulat5280';
        document.body.appendChild(watermark);

        // Проверка водяного знака каждые 2 секунды
        const intervalId = setInterval(() => {
            if (!document.getElementById('watermark')) {
                // Восстанавливаем водяной знак, если его удалили
                document.body.appendChild(watermark);
            }
        }, 2000);

        // Очищаем интервал при размонтировании компонента
        return () => clearInterval(intervalId);
    }, []);

    return null;
}

export default Watermark;
