import React, {useEffect, useState} from 'react';
import './../styles/AddTask.css'; // Добавьте этот файл для кастомных стилей

const Addtask = () => {
    // Устанавливаем состояние для полей формы и уведомления
    const [formData, setFormData] = useState({
        link: '',
        channelId: '',
        amount: '',
        reward: '',
        rewardType: 'points'
    });

    const [showNotification, setShowNotification] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationType, setNotificationType] = useState('success'); // success or error

    // Обработка изменения значений полей
    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    // Функция для показа уведомления
    const showCustomNotification = (message, type) => {
        setNotificationMessage(message);
        setNotificationType(type);
        setShowNotification(true);

        // Скрыть уведомление через 3 секунды
        setTimeout(() => {
            setShowNotification(false);
        }, 3000);
    };

    // Обработка отправки формы
    const handleSubmit = (e) => {
        e.preventDefault();

        // Имитируем успешную отправку для теста уведомления
        fetch('https://piratscoin.com:5000/api/user/addtask', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                link: formData.link,
                channelId: formData.channelId,
                amount: formData.amount,
                reward: formData.reward,
                rewardType: formData.rewardType
            })
        })
            .then(response => response.json())
            .then(data => {
                showCustomNotification('Задание успешно добавлено!', 'success');
            })
            .catch(error => {
                showCustomNotification('Ошибка при добавлении задания', 'error');
                console.error('Ошибка при добавлении задачи:', error);
            });
    };
    useEffect(() => {
        document.body.className = 'defaultBackground'; // Устанавливаем класс для body

        return () => {
            document.body.className = ''; // Очищаем класс при размонтировании компонента
        };
    }, []);
    return (
        <div>
            <h2>Добавить задание</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>
                        Ссылка на канал:
                        <input
                            type="text"
                            name="link"
                            value={formData.link}
                            onChange={handleChange}
                        />
                    </label>
                </div>
                <div>
                    <label>
                        Айди канала:
                        <input
                            type="text"
                            name="channelId"
                            value={formData.channelId}
                            onChange={handleChange}
                        />
                    </label>
                </div>
                <div>
                    <label>
                        Награда:
                        <input
                            type="text"
                            name="reward"
                            value={formData.reward}
                            onChange={handleChange}
                        />
                    </label>
                </div>
                <div>
                    <label>
                        Тип Награды:
                        <select
                            name="rewardType"
                            value={formData.rewardType}
                            onChange={handleChange}
                        >
                            <option value="PIASTR">PIASTR</option>
                            <option value="balance">TON</option>
                            <option value="atl">ATL</option>
                            <option value="availableClicks">Клики</option>
                        </select>

                    </label>
                </div>
                <div>
                    <label>
                        Кол-во выполнений:
                        <input
                            type="text"
                            name="amount"
                            value={formData.amount}
                            onChange={handleChange}
                        />
                    </label>
                </div>

                <button type="submit">Add Task</button>
            </form>

            {/* Кастомное уведомление */}
            {showNotification && (
                <div className={`notification ${notificationType}`}>
                    {notificationMessage}
                </div>
            )}
        </div>
    );
};

export default Addtask;
