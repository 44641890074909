import React, {useEffect, useState} from 'react';
import './../styles/Tasks.css';
import {NavLink} from "react-router-dom";

const Tasks = () => {
    const [tasks, setTasks] = useState([]);
    const [checkingTaskId, setCheckingTaskId] = useState(null);
    const [errorMsg, setErrorMsg] = useState('');

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            fetchTasks(token);
        } else {
            const map = {};
            const foo = () => {
                const token = localStorage.getItem('token');
                if (token) {
                    fetchTasks(token);
                    clearInterval(map.interval);
                }
            };
            map.interval = setInterval(foo, 1000);
        }
    }, []);

    const fetchTasks = (token) => {
        fetch('https://piratscoin.com:5000/api/user/tasks', {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json())
            .then(data => {
                setTasks(data.tasks)
                if (data.admin) {
                    document.getElementById('addtask').style.visibility = 'visible';
                }
            })
            .catch(error => console.error('Ошибка при получении заданий:', error));
    };

    const handleButtonClick = (taskId, channelUrl) => {
        if (checkingTaskId === taskId) {
            checkTaskCompletion(taskId);
        } else {
            window.Telegram.WebApp.openTelegramLink(channelUrl)
            //window.open(channelUrl, '_blank');
            setCheckingTaskId(taskId);
        }
    };

    const checkTaskCompletion = (taskId) => {
        const token = localStorage.getItem('token');
        fetch(`https://piratscoin.com:5000/api/user/check?id=${taskId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json())
            .then(data => {
                if (data.completed) {
                    setTasks(prevTasks => prevTasks.filter(task => task.id !== taskId));
                    setCheckingTaskId(null); // Сбрасываем проверяемое задание
                } else {
                    setErrorMsg('Задание не выполнено');
                    setTimeout(() => setErrorMsg(''), 3000);
                }
            })
            .catch(error => console.error('Ошибка при проверке задания:', error));
    };

    const formatReward = (reward, rewardType) => {
        let result = '';
        if (reward > 0) result += '+';
        else if (reward < 0) result += '-';
        result += Math.abs(reward);
        result += ' ';
        switch (rewardType) {
            case 'points':
                result += 'PIASTR';
                break;
            case 'balance':
                result += 'TON';
                break;
            case 'atl':
                result += 'ATL';
                break;
            case 'availableClicks':
                result += 'Кликов';
                break;
            default:
                result += '';
        }
        return result;
    }
    useEffect(() => {
        document.body.className = 'specialBackground'; // Устанавливаем класс для body

        return () => {
            document.body.className = ''; // Очищаем класс при размонтировании компонента
        };
    }, []);
    return (
        <div>
            <h1>
                <center>QUESTS</center>
                <NavLink to={"/addtask"} id={"addtask"} className={"addtask"} style={{visibility: "hidden"}}></NavLink>
            </h1>

            <div className="tasksContainer">
                {tasks.length > 0 ? tasks.map(task => (
                    <div className={"task"} key={task.id}>
                        <div className={"taskInfo"}>
                            <span>Вступить в сообщество</span>
                            <span>{formatReward(task.reward, task.rewardType)}</span>
                        </div>
                        <button
                            className={"taskCheck"}
                            onClick={() => handleButtonClick(task.id, task.link)}
                        >
                            {checkingTaskId === task.id ? 'Проверить' : 'Перейти'}
                        </button>
                        {checkingTaskId === task.id && errorMsg && (
                            <div className="errorMsg">{errorMsg}</div>
                        )}
                    </div>
                )) : <div className={"task"}>
                    <div className={"taskInfo"}>
                        <span>Задания закончились</span>
                    </div>
                </div>}
            </div>
        </div>
    );
};

export default Tasks;
