import React, {useEffect, useState} from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Main from "./pages/main";
import './styles/App.css';
import Navbar from "./components/Navbar";
import Ref from "./pages/ref";
import Leaderboard from "./pages/leaderboard";
import News from "./pages/news";
import Boost from "./pages/boost";
import Tasks from "./pages/tasks"
import Addtask from "./pages/addtask";
import Watermark from "./components/Watermark";

function App() {
    const [isTelegramWebApp, setIsTelegramWebApp] = useState(true);
    useEffect(() => {
        const tg = window.Telegram.WebApp;
        tg.expand();
        tg.disableVerticalSwipes();
        tg.enableClosingConfirmation()
        const initData = tg.initData;
        if (initData) {
            fetch('https://piratscoin.com:5000/api/auth/telegram', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({initData})
            })
                .then(response => response.json())
                .then(data => {
                    if (data.token) {
                        localStorage.setItem('token', data.token);
                    } else {
                        console.error('Authentication failed');
                    }
                })
                .catch(error => {
                    console.error('Error during authentication:', error);
                });
        } else {
            //setIsTelegramWebApp(false);
        }

        tg.ready();
    }, []);

    useEffect(() => {
        const body = document.body;
        const savedTheme = localStorage.getItem('theme');
        const toggle = document.getElementById('theme-toggle');

        if (savedTheme === 'dark') {
            body.classList.add('dark-mode');
            if (toggle) toggle.checked = true;
        }

        const handleThemeToggle = () => {
            if (toggle.checked) {
                body.classList.add('dark-mode');
                localStorage.setItem('theme', 'dark');
            } else {
                body.classList.remove('dark-mode');
                localStorage.setItem('theme', 'light');
            }
        };

        if (toggle) {
            toggle.addEventListener('change', handleThemeToggle);
        }

        // Удаляем обработчик события при размонтировании компонента
        return () => {
            if (toggle) {
                toggle.removeEventListener('change', handleThemeToggle);
            }
        };
    }, []); // Пустой массив зависимостей, чтобы этот эффект запускался только один раз
    if (!isTelegramWebApp) {
        return <div>Это приложение доступно только через Telegram</div>;
    }//<Header/>
    return (
        <BrowserRouter>
            <Watermark/>
            <Routes>
                <Route path="/" element={<Main/>}/>
                <Route path="/boost" element={<Boost/>}/>
                <Route path="/ref" element={<Ref/>}/>
                <Route path="/leaderboard" element={<Leaderboard/>}/>
                <Route path="/news" element={<News/>}/>
                <Route path="/tasks" element={<Tasks/>}/>
                <Route path="/addtask" element={<Addtask/>}/>
            </Routes>
            <Navbar/>

        </BrowserRouter>
    );
}

/*<div className="theme-switcher">
                <input type="checkbox" id="theme-toggle"/>
                <label htmlFor="theme-toggle" className="toggle-label">
                    <span className="toggle-ball"></span>
                </label>
            </div>*/
export default App;
