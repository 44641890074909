import React, {useEffect, useState} from 'react';
import './../styles/Ref.css'

const Ref = () => {


    let [link, setLink] = useState("https://t.me/share/url?url=https://t.me/Piastracoin_bot/app?startapp=1402188400&text=The best Project");
    let [referrals, setReferrals] = useState(0);
    useEffect(() => {
        // Инициализация Telegram WebApp
        const tg = window.Telegram.WebApp;
        let id = '1402188400';
        if (tg && tg.initDataUnsafe && tg.initDataUnsafe.user && tg.initDataUnsafe.user.id) {
            id = tg.initDataUnsafe.user.id;
        }
        setLink("https://t.me/share/url?url=https://t.me/Piastracoin_bot/app?startapp=" + id + "&text=The best Project")

        // Получаем токен из localStorage
        const token = localStorage.getItem('token');

        // Если токен существует, запрашиваем баланс
        if (token) {
            fetch('https://piratscoin.com:5000/api/user/referrals', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Failed to fetch referrals');
                    }
                    return response.json();
                })
                .then(data => {
                    setReferrals(data.referralCount); // Устанавливаем баланс в состояние
                })
                .catch(error => {
                    console.error('Error fetching referrals:', error);
                });

        } else {
            const map = {}
            const foo = () => {
                // Получаем токен из localStorage
                const token = localStorage.getItem('token');

                // Если токен существует, запрашиваем баланс
                if (token) {
                    fetch('https://piratscoin.com:5000/api/user/referrals', {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(response => {
                            if (!response.ok) {
                                throw new Error('Failed to fetch referrals');
                            }
                            return response.json();
                        })
                        .then(data => {
                            setReferrals(data.referralCount);
                        })
                        .catch(error => {
                            console.error('Error fetching referrals:', error);
                        });
                    clearInterval(map.interval)
                }
            }
            map.interval = setInterval(foo, 1000)
        }
        tg.ready();


    });
    const copy = () => {
        const tempInput = document.createElement("input");
        tempInput.value = link;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
    }
    useEffect(() => {
        document.body.className = 'specialBackground'; // Устанавливаем класс для body

        return () => {
            document.body.className = ''; // Очищаем класс при размонтировании компонента
        };
    }, []);
    return (
        <div>
            <h1>
                <center>FRIENDS</center>
            </h1>
            <div className={"me"}>
                <div className={"myData"}>
                    <span className={"myPosition"}>{referrals}</span>
                </div>

            </div>
            <div className={"tip"}>Нажмите на кнопку, чтобы пригласить своих друзей</div>
            <button onClick={() => {
                window.Telegram.WebApp.openTelegramLink(link)
            }} target="_blank" rel="noopener noreferrer"
                    className={"buttonWrap"}>
                <div className={"button"}>Пригласить друзей</div>
            </button>
        </div>
    );
};

export default Ref;