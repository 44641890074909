import React from 'react';
import {NavLink} from "react-router-dom";
import Home from "../assets/home.png"
import Ref from "../assets/ref.png"
import Leaderboard from "../assets/leaderboard.png"
import News from "../assets/news.svg"
import Tasks from "../assets/tasks.png"

const Navbar = () => {
    return (
        <nav className={"navbar"}>
            <ul>
                <li>
                    <NavLink className={(navData) => (navData.isActive ? "active-style" : 'none')} to="/"><img
                        className={"linkimg"} src={Home} alt={""}></img></NavLink>
                </li>
                <li>
                    <NavLink className={(navData) => (navData.isActive ? "active-style" : 'none')} to="/tasks"><img
                        className={"linkimg"} src={Tasks} alt={""}></img></NavLink>
                </li>
                <li>
                    <NavLink className={(navData) => (navData.isActive ? "active-style" : 'none')} to="/ref"><img
                        className={"linkimg"} src={Ref} alt={""}></img></NavLink>
                </li>
                <li>
                    <NavLink className={(navData) => (navData.isActive ? "active-style" : 'none')}
                             to="/leaderboard"><img className={"linkimg"} src={Leaderboard} alt={""}></img></NavLink>
                </li>
                <li>
                    <NavLink className={(navData) => (navData.isActive ? "active-style" : 'none')} to="/news"><img
                        className={"linkimg"} src={News} alt={""}></img></NavLink>
                </li>
            </ul>
        </nav>
    );
};

export default Navbar;