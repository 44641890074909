import React, {useEffect, useState} from 'react';
import Back from './../assets/back.svg';
import {NavLink} from "react-router-dom";
import './../styles/Boost.css';
import boostClicks from './../assets/boostClicks.svg';
import boostDoubleMining from './../assets/boostDoubleMining.svg';
import boostDoubleAll from './../assets/boostDoubleAll.svg';

const Boost = () => {
    // Состояние для управления видимостью PopUp и его содержимым
    const [popupData, setPopupData] = useState(null);

    // Данные о бустах
    const boosts = [
        {
            id: 1,
            title: 'Доп. клики',
            description: 'Подробное и очень подробное описание для Доп. клики',
            img: boostClicks
        },
        {
            id: 2,
            title: 'x2 Майнинг',
            description: 'Подробное и очень подробное описание для x2 Майнинг',
            img: boostDoubleMining
        },
        {
            id: 3,
            title: 'x2 Всё',
            description: 'Подробное и очень подробное описание для x2 Всё',
            img: boostDoubleAll
        },
    ];

    // Функция для открытия PopUp
    const openPopup = (boost) => {
        setPopupData(boost);
    };

    // Функция для закрытия PopUp
    const closePopup = () => {
        setPopupData(null);
    };
    useEffect(() => {
        document.body.className = 'defaultBackground'; // Устанавливаем класс для body

        return () => {
            document.body.className = ''; // Очищаем класс при размонтировании компонента
        };
    }, []);
    return (
        <div>
            <div className="topPanel">
                <div className={"boostTitleContainer"}>
                    <NavLink to={"/"}><img className={"backButton"} src={Back} alt={"Back"}/></NavLink>
                    <span className={"boostsTitle"}>Буст</span>
                </div>
            </div>
            <div className={"boostsContainer"}>
                {boosts.map(boost => (
                    <div key={boost.id} className={"boostCard"} onClick={() => openPopup(boost)}>
                        <img className={"boostCardImage"} src={boost.img} alt={boost.title}/>
                        <span className={"boostCardTitle"}>{boost.title}</span>
                    </div>
                ))}
            </div>

            {popupData && (
                <div className="popup">
                    <div className="popup-content">
                        <span className="close" onClick={closePopup}>&times;</span>
                        <img src={popupData.img} alt={popupData.title} className="popupImage"/>
                        <h2>{popupData.title}</h2>
                        <p>{popupData.description}</p>
                        <button className="buyButton">Купить</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Boost;
