import React, {useEffect} from 'react';
import ReactMarkdown from 'react-markdown';
import './../styles/News.css'

const News = () => {
    const messageTitle = `ЗАГОЛОВОК НОВОСТИ`;

    const messageContent = `ТЕКСТ НОВОСТИ`;
    useEffect(() => {
        document.body.className = 'defaultBackground'; // Устанавливаем класс для body

        return () => {
            document.body.className = ''; // Очищаем класс при размонтировании компонента
        };
    }, []);
    return (
        <div>
            <h1>
                <center>Новости</center>
            </h1>

            <div className={"container"}>
                <div className={"message"}>
                    <ReactMarkdown className={"messageTitle"}>
                        {messageTitle}
                    </ReactMarkdown>
                    <ReactMarkdown className={"messageContent"}>
                        {messageContent}
                    </ReactMarkdown>
                </div>
            </div>

        </div>
    );
};

export default News;
