import React, {useEffect, useState} from 'react';
import './../styles/Leaderboard.css'; // Подключите стили

const Leaderboard = () => {
    const [myPosition, setMyPosition] = useState('Infinity');
    const [top100, setTop100] = useState([]);
    const [timeRange, setTimeRange] = useState('all-time'); // Добавляем состояние для выбранного диапазона

    // Функция для форматирования числа очков
    const formatPoints = (points) => {
        if (points >= 1000000000000) {
            return (points / 1000000000000).toFixed(2).replace(/\.00$/, '') + 'T';
        } else if (points >= 1000000000) {
            return (points / 1000000000).toFixed(2).replace(/\.00$/, '') + 'B';
        } else if (points >= 1000000) {
            return (points / 1000000).toFixed(2).replace(/\.00$/, '') + 'M';
        } else if (points >= 1000) {
            return (points / 1000).toFixed(2).replace(/\.00$/, '') + 'K';
        } else {
            return points.toString();
        }
    };

    // Функция для получения позиции пользователя
    const fetchMyPosition = async () => {
        let token = localStorage.getItem('token');
        if (token) {
            try {
                const response = await fetch('https://piratscoin.com:5000/api/user/position', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}` // Добавьте токен авторизации
                    }
                });
                const data = await response.json();
                setMyPosition(`${data.position}`);
            } catch (error) {
                console.error('Failed to fetch user position:', error);
            }
        } else {
            const map = {}
            const foo = async () => {
                const token = localStorage.getItem('token');
                if (token) {
                    try {
                        const response = await fetch('https://piratscoin.com:5000/api/user/position', {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}` // Добавьте токен авторизации
                            }
                        });
                        const data = await response.json();
                        setMyPosition(`${data.position}`);
                    } catch (error) {
                        console.error('Failed to fetch user position:', error);
                    }
                    clearInterval(map.interval)
                }
            }
            map.interval = setInterval(foo, 1000)
        }
    };

    // Функция для получения топ-100 пользователей
    const fetchTop100 = async (range) => {
        let url;
        if (range === 'all-time') {
            url = 'https://piratscoin.com:5000/api/top-100';
        } else if (range === 'weekly') {
            url = 'https://piratscoin.com:5000/api/top-100-weekly';
        } else if (range === 'monthly') {
            url = 'https://piratscoin.com:5000/api/top-100-monthly';
        }

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();
            data.list.length = 8;
            setTop100(data.list);
        } catch (error) {
            console.error('Failed to fetch top-100:', error);
        }
    };

    // Загрузка данных при монтировании компонента
    useEffect(() => {
        fetchMyPosition();
        fetchTop100(timeRange);
    }, [timeRange]); // Обновляем данные при изменении диапазона

    useEffect(() => {
        document.body.className = 'specialBackground'; // Устанавливаем класс для body

        return () => {
            document.body.className = ''; // Очищаем класс при размонтировании компонента
        };
    }, []);
    /*<div className={"timeRangeSelector"}>
                <button onClick={() => setTimeRange('weekly')} className={timeRange === 'weekly' ? ' active' : ''}>
                    За неделю
                </button>
                <button onClick={() => setTimeRange('monthly')} className={timeRange === 'monthly' ? ' active' : ''}>
                    За месяц
                </button>
                <button onClick={() => setTimeRange('all-time')}
                        className={"" + timeRange === 'all-time' ? ' active' : ''}>
                    За всё время
                </button>

            </div>*/
    /*<span className={"userBalance"}>{formatPoints(user.points)} PIASTR</span>*/
    return (
        <div>
            <h1>
                <center>RATING</center>
            </h1>
            <div className={"me"}>
                <div className={"myData"}>
                    YOUR PLACE
                    <span className={"myPosition"}>{myPosition}</span>
                </div>

            </div>

            <div className={"containerLB"}>

                {top100.map((user, index) => (
                    <div className={"user"} key={index}>
                        <div className={"userData"}>
                            <span className={"userName"}>{user.rank}. {user.name}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );/*<h2 className={"holdersHeader"}>{formatPoints(amount)} holders</h2>*/
};

export default Leaderboard;
