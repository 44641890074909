import React, {useEffect, useRef, useState} from 'react';
import './../styles/Main.css';

function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }

        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

const Main = () => {
    let [dots, setDots] = useState([]);
    let [points, setPoints] = useState(0);
    let [availableClicks, setAvailableClicks] = useState(100);
    let [maxAvailableClicks, setMaxAvailableClicks] = useState(100);
    let [mining, setMining] = useState(0);
    let [totalMined, setTotalMined] = useState(0);
    let [mineButton, setMineButton] = useState("START MINNING")
    let [position, setPosition] = useState(Infinity);
    let [isRequestPending, setIsRequestPending] = useState(false); // Новое состояние
    const maxSpeed = 100; // Минимальная продолжительность (максимальная скорость)
    let curSpeed = 1;
    const minebutton = document.getElementById('minebutton');

    setInterval(() => {
        if (curSpeed > 1) {
            curSpeed--;
            dots.forEach(dot => {
                const animation = dot.getAnimations()[0];
                const newDuration = dot.data.options.duration * (1 / (curSpeed));
                const oldDuration = animation.effect.getTiming().duration;
                animation.effect.updateTiming({duration: newDuration});
                animation.currentTime = animation.currentTime / oldDuration * newDuration;
            });
        }
    }, 500);

    const clickmine = (event) => {
        if (isRequestPending) return; // Если запрос в обработке, не позволяем кликать
        if (curSpeed < maxSpeed) {
            curSpeed += 5;
            dots.forEach(dot => {
                const animation = dot.getAnimations()[0];
                const newDuration = dot.data.options.duration * (1 / (curSpeed));
                const oldDuration = animation.effect.getTiming().duration;
                animation.effect.updateTiming({duration: newDuration});
                animation.currentTime = animation.currentTime / oldDuration * newDuration;
            });
        }

        minebutton.style.width = "130px";
    };

    const releasemine = (event) => {
        if (isRequestPending) return; // Если запрос в обработке, не позволяем кликать

        minebutton.style.width = "150px";

        setIsRequestPending(true); // Устанавливаем флаг запроса в обработке

        fetch('https://piratscoin.com:5000/api/user/click', {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data.availableClicks)
                    setAvailableClicks(data.availableClicks);
                else
                    setAvailableClicks(0);
                if (data.points)
                    setPoints(data.points)
                else if (data.success)
                    setPoints(points + 1);
                if (data.maxAvailableClicks)
                    setMaxAvailableClicks(data.maxAvailableClicks)
            })
            .catch(error => console.error('Error updating clicks:', error))
            .finally(() => setIsRequestPending(false)); // Разрешаем кликать снова после завершения запроса
    };

    useEffect(() => {
        // Получаем токен из localStorage
        const token = localStorage.getItem('token');

        // Если токен существует, запрашиваем баланс
        if (token) {
            fetch('https://piratscoin.com:5000/api/user/main', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Failed to fetch points');
                    }
                    return response.json();
                })
                .then(data => {
                    setPoints(data.points); // Устанавливаем баланс в состояние
                    setAvailableClicks(data.availableClicks);
                    setPosition(data.position)
                    setMining(data.mining);
                    setMaxAvailableClicks(data.maxAvailableClicks)
                    setTotalMined(data.totalMined)
                    setMineButton(data.mining === 0 ? "START MINNING" : `COLLECT (${data.totalMined} PIASTR)`)
                    if (data.admin) ;
                })
                .catch(error => {
                    console.error('Error fetching points:', error);
                });

        } else {
            const map = {}
            const foo = () => {
                // Получаем токен из localStorage
                const token = localStorage.getItem('token');

                // Если токен существует, запрашиваем баланс
                if (token) {
                    fetch('https://piratscoin.com:5000/api/user/main', {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(response => {
                            if (!response.ok) {
                                throw new Error('Failed to fetch points');
                            }
                            return response.json();
                        })
                        .then(data => {
                            setPoints(data.points); // Устанавливаем баланс в состояние
                            setAvailableClicks(data.availableClicks);
                            setPosition(data.position);
                            setMining(data.mining);
                            setMaxAvailableClicks(data.maxAvailableClicks)
                            setTotalMined(data.totalMined)
                            setMineButton(data.mining === 0 ? "START MINNING" : `COLLECT (${data.totalMined} PIASTR)`)
                        })
                        .catch(error => {
                            console.error('Error fetching points:', error);
                        });
                    clearInterval(map.interval)
                }
            }
            map.interval = setInterval(foo, 1000)
        }

        const numDots = 5; // Количество точек

        // Функция для генерации случайного цвета в диапазоне от жёлтого до красного
        const getRandomColor = () => {
            const hue = Math.floor(Math.random() * 90) + 150; // От 0 до 30 градусов (от красного до жёлтого)
            return `hsl(${hue}, 100%, 50%)`;
        };

        const getRandomRadius = () => Math.floor(Math.random() * 20) + 100;

        let dots_ = [];

        for (let i = 0; i < numDots; i++) {
            const dot = document.createElement('div');
            dot.classList.add('dot');

            const size = Math.random() * 10 + 10; // от 10px до 40px
            dot.style.width = `${size}px`;
            dot.style.height = `${size}px`;

            //dot.style.backgroundColor = getRandomColor();

            const radius = getRandomRadius();
            const angle = Math.random() * 360;

            const duration = (Math.random() * 2 + 2) * 1000; // от 5s до 10s
            dot.data = {
                keyframes: [
                    {transform: `rotate(${angle}deg) translate(${radius}px)`},
                    {transform: `rotate(${angle + 360}deg) translate(${radius}px)`}
                ],
                options: {
                    duration: duration,
                    iterations: Infinity
                }
            };
            dot.animate(dot.data.keyframes, dot.data.options);

            document.body.appendChild(dot);
            dots_.push(dot);
        }
        setDots(dots_);
        return () => {
            const dots = document.querySelectorAll('.dot');
            dots.forEach(dot => dot.remove());
        };
    }, [mining, totalMined]); // Пустой массив зависимостей означает, что этот код выполнится один раз при монтировании


    const collect = () => {
        fetch('https://piratscoin.com:5000/api/user/collect', {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data.mining) {
                    setMining(data.mining)
                }
                if (data.points) {
                    setPosition(data.points)
                }
                setTotalMined(0)
                setMineButton(`COLLECT (0 PIASTR)`)
            })
            .catch(error => console.error('Error updating clicks:', error))
            .finally(() => {
            }); // Разрешаем кликать снова после завершения запроса
    }
    useEffect(() => {
        document.body.className = 'defaultBackground'; // Устанавливаем класс для body

        return () => {
            document.body.className = ''; // Очищаем класс при размонтировании компонента
        };
    }, []);
    /*<div className={"clan"}>
                <div className={"centerContainer"}>КЛАН<br/>(В разработке)</div>
            </div>
            <div className={"position"}>
                <div className={"centerContainer"}>{position}<br/>место</div>
            </div>
            <div className={"boost"}>
                <div className={"centerContainer"}>
                    <NavLink className={"boostButton"} to="/boost"></NavLink>
                    <img className={"linkimg"} src={Boost} alt={""}/>БУСТ
                </div>
            </div>*/
    return (
        <div>

            <span className={"countOfClicks"}><span>PIASTRA COIN</span><span>{points}</span></span>
            <button id="minebutton" className="minebutton"
                    onTouchStart={clickmine} onTouchEnd={releasemine}
                    onMouseUp={releasemine} onMouseDown={clickmine}
                    disabled={isRequestPending}> {/* Отключаем кнопку, если запрос в обработке */}
                <div id="mineimg" className="mineimg" onContextMenu={() => false}></div>
            </button>
            <div className={"counter"}>{availableClicks}/{maxAvailableClicks}</div>

            <div className={"buttonWrap"}>
                <div className={"button"}
                     onClick={collect}>{mineButton}</div>
            </div>
        </div>
    );/*<div className={"speed"}>+{mining}/час</div>*/
};

export default Main;
